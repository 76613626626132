import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash"
export const getPriceStr = (price) => {
  let curr = 'AED';
  if(typeof window !== 'undefined'){
    if(localStorage.getItem('currTo')){
      curr = localStorage.getItem('currTo')
    }
  } 
  
  let priceStr = '';
  if (parseInt(price.min) && parseInt(price.max)) {
    priceStr = " between " + curr + ' ' + addCommas(price.min) + " and " + curr + ' ' + addCommas(price.max);
  } else if (parseInt(price.max)) {
    priceStr = " below " + curr + ' ' + addCommas(price.max);
  } else if (parseInt(price.min)) {
    priceStr = " above " + curr + ' ' + addCommas(price.min);
  }
  return priceStr;
}

function addCommas(num) {
  // console.log('num : ' + num);
  var str = num.toString().split('.');
  // console.log('str, ' + str);
  if (str[0].length >= 4) {
    // add comma every 3 digits before decimal
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join('.');
}

export const ACTION_TYPES = {
  sales: " for sale in ",
  lettings: " for rent in ",
}

export const ACTION_DESC_TYPES = {
  sales: " for sale ",
  lettings: " for rent ",
}

export const pTypeBase = {
  sales: "/properties/for-sale/",
  lettings: "/properties/for-rent/",
}

export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if (_text) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if (_text.length > 0) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
}

// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (pType, location, minLabel, maxLabel) => {
  if (location) {
    var query = '';
    let pathUri_main = location.split(pTypeBase[pType].slice(0, -1))
    var areaVal = ''
    var bedVal = ''
    var minpriceVal = ''
    var maxpriceVal = ''
    var typeVal = ''
    var finderVal = ''
    var subFinderVal = ''
    
    if (pathUri_main[1]) { // following could be regexp
      let pathUri = pathUri_main[1].split('/');

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") { continue; }
        // for-sale
        if (pathUri[vi].indexOf("for-sale") >= 0) {
          // its area
          pType = 'sales';
        }
        if (pathUri[vi].indexOf("for-rent") >= 0) {
          // its area
          pType = 'lettings';
        }
        if (pathUri[vi].indexOf("in-") >= 0) {
          // its area
          areaVal = pathUri[vi].replace("in-", "").replace("-", " ");
        }
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          // its bedrooms
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
        }
        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }
        if (pathUri[vi].indexOf("between-aed") >= 0 ||
          pathUri[vi].indexOf("above-aed") >= 0 ||
          pathUri[vi].indexOf("below-aed") >= 0
        ) {
          // its price
          let priceFilt1 = pathUri[vi].split('above-aed');
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split('below-aed');
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split('between-aed');
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split('-and-aed');
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }
        let priceFilt5 = pathUri[vi].split('type-');
        if (priceFilt5[1]) {
          typeVal = priceFilt5[1]
        }
         // finder
         let finder = pathUri[vi].split('finder-');
         if( finder[1] ) {
          finderVal = finder[1]
         }

         
         // Sub Finder
         let sub_finder = pathUri[vi].split('sub-area-');
         if( sub_finder[1] ) {
          subFinderVal = sub_finder[1]
         }
      }
    }

    // If no area send default to set default loaction
    // We could send defaut location
    if (!areaVal || areaVal === 'undefined') {
      areaVal = "Dubai"; // TODO
    }

    var areaAlias = areaVal.replace(' ', '-');

    return {
      pType: pType,
      areas: areaVal.replace(/-/g, ' '),
      areas_alias: areaAlias,
      bedrooms: bedVal,
      type: typeVal,
      finder: finderVal,
      sub_finder:subFinderVal,
      price: {
        min: minpriceVal,
        max: maxpriceVal,
      },
      priceLabel: {
        min: minLabel,
        max: maxLabel,
      },
      extra: {
        bedPlus: false,
        areasTitleCase: true
      }
    }
  }
}

// TODO: hard coded details to be removed/replaced with common variables
export const savedSearchParams = (params) => {
  const { areas, bedrooms, pType, type, price } = params;

  return {
    department: "residential",
    ptype: pType,
    areas: areas,
    bedrooms: bedrooms,
    minPrice: price.min,
    maxPrice: price.max,
    building: type,
  }
}


// TODO: hard coded details to be removed/replaced with common variables
export const propertyH1 = (params, intro, nego_name) => {
  // console.log('params : ' + params);

  const { areas, bedrooms, pType, type, price, extra, priceLabel } = params;
  // console.log('areas : ' + areas);

  let h1 = "";
  let building = "Properties";


  if (type) {
    var typeReplace = type
    switch (typeReplace) {
      case "apartment":
        typeReplace = "apartments";
        break;
      case "villa":
        typeReplace = "villas";
        break;
      case "townhouse":
        typeReplace = "townhouses";
        break;
      case "penthouse":
        typeReplace = "penthouses";
        break;
      case "land":
        typeReplace = "lands";
        break;
      case "plot":
        typeReplace = "plots";
        break;
      case "full-floor":
        typeReplace = "full-floors";
        break;
      case "hotel-apartment":
        typeReplace = "hotel-apartments";
        break;
    }
    building = ` ${typeReplace.replace("-", " ")}`;
  } else {
    building = ` Properties `;
  }

  if(nego_name){
    building = `${nego_name} ${building.toLowerCase()}`;
  }

  if (intro) {
    h1 += building.toLowerCase();
  } else {
    h1 +=  _.capitalize(_.trim(building));
  } 
  h1 += ACTION_TYPES[pType];

  if (areas) {
    let _areas = areas;
    _areas = _areas.replace("-", " ");
    _areas = decodeURIComponent(_areas);
    _areas = _.startCase(_.capitalize(_areas));
    h1 += _areas
  }

  if (bedrooms) {

    if (bedrooms > 1) {
      h1 += ` with more than ${bedrooms} bedrooms`;
    } else {
      h1 += ` with more than ${bedrooms} bedroom`;
    }
  }
  let min = priceLabel?.min
  let max = priceLabel?.max
  if (price && (price.min || price.max)) {

    let minprice = typeof min === 'string' ? min.replace(/,/g, '').slice(0, -3) : null
    let minpricecurr = typeof min === 'string' ? min.slice(-3) : null
    let maxprice = typeof max === 'string' ? max.replace(/,/g, '').slice(0, -3) : null
    let maxpricecurr = typeof max === 'string' ? max.slice(-3) : null
    let priceLabel = {
      min: minprice,
      max: maxprice
    }
    
    let currencyLabel = {
      min: minpricecurr,
      max: maxpricecurr
    }
    h1 += getPriceStr(priceLabel, currencyLabel);
  }


  // console.log("H1 title => ", h1);
  return h1;
}